<template>
  <!--
    A form to input the startFinishConfig-data and update it.
  -->
  <div class="startFinishConfigRuntimeForm">
    <LoadingPlaceholder v-if="loading" />

    <template v-else>
      <template v-if="configData">
        <h4 class="m-0 p-0 mt-3">
          {{ $t('runtime') }}
        </h4>
        <hr class="p-0 m-0 mb-3">

        <div class="form-group">
          <label>{{ $t('preRuntimeMaximum') }}</label>
          <input
            v-model="configData.preRuntimeMaximum"
            :class="['form-control', { 'is-invalid': errors.has('PreRuntimeMaximum') }]"
            type="number"
          >
          <span
            v-show="errors.has('PreRuntimeMaximum')"
            class="badge badge-danger"
          >{{ errors.first('PreRuntimeMaximum') }}</span>
        </div>

        <div class="form-group">
          <label>{{ $t('runtimeMaximum') }}</label>
          <input
            v-model="configData.runtimeMaximum"
            :class="['form-control', { 'is-invalid': errors.has('RuntimeMaximum') }]"
            type="number"
          >
          <span
            v-show="errors.has('RuntimeMaximum')"
            class="badge badge-danger"
          >{{ errors.first('RuntimeMaximum') }}</span>
        </div>

        <div class="form-group">
          <label>{{ $t('startFinishConfigDisplayComponent.runtimeMinimum') }}</label>
          <input
            v-model="configData.runtimeMinimum"
            :class="['form-control', { 'is-invalid': errors.has('RuntimeMinimum') }]"
            type="number"
          >
          <span
            v-show="errors.has('RuntimeMinimum')"
            class="badge badge-danger"
          >{{ errors.first('RuntimeMinimum') }}</span>
        </div>

        <div class="form-group">
          <label>{{ $t('startWithIdentification') }}</label>
          <toggle-button
            v-model="configData.startWithIdentification"
            :labels="{ checked: $t('true'), unchecked: $t('false') }"
            :class="['m-0 ml-4 mt-2', { 'is-invalid': errors.has('StartWithIdentification') }]"
            :width="70"
            :height="30"
            :font-size="12"
          />
          <span
            v-show="errors.has('StartWithIdentification')"
            class="badge badge-danger"
          >{{ errors.first('StartWithIdentification') }}</span>
        </div>

        <button
          class="btn btn-primary float-right"
          @click="setConfig()"
        >
          <font-awesome-icon
            class="mr-1"
            icon="upload"
          />
          <span>
            {{ $t('save') }}
          </span>
        </button>

        <div class="clearfix" />
      </template>

      <template v-else>
        {{ $t('noDataAvailable') }}
      </template>
    </template>
  </div>
</template>

<script>
import { errorMixin } from '@/mixins/errorMixin.js';

export default {
  name: 'StartFinishConfigRuntimeForm',
  mixins: [
    errorMixin
  ],
  props: {
    installationId: {
      type: String,
      required: true
    },
    laneNumber: {
      type: String,
      required: false,
      default () {
        return null;
      }
    }
  },
  data () {
    return {
      loading: true,
      configData: null
    }
  },
  created () {
    this.getConfig();
  },
  methods: {
    getConfig () {
      let url = `/Config/GetStartFinishConfig?installationId=${ this.installationId }`; 
      if (this.laneNumber)
      {
        url += `&laneNumber=${ this.laneNumber }`
      }
      this.axios.get(url)
        .then((response) => {
          this.configData = response.data;
          this.error_clear();
          this.hideSidebar();
        })
        .finally(() => {
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
    setConfig () {
      let url = `/Config/UpdateStartFinishConfig?installationId=${ this.installationId }`; 
      if (this.laneNumber)
      {
        url += `&laneNumber=${ this.laneNumber }`
      }
      this.axios.post(url, this.configData)
        .then((response) => {
          if (response.status == 204) {
            this.$emit('hide');
            this.$emit('setReloadButton', true);
            this.$snotify.error(this.$t('configReload.failedReload'));
          } else {
            this.$emit('setReloadButton', false);
            if (response.status != 200) {
              return;
            }
          }

          this.$snotify.success(this.$t('startFinishConfigRuntimeFormComponent.configWasSaved'));
          this.getConfig();
          this.error_clear();
          this.$emit('getConfig');
        })
        .catch((error) => {
          this.$snotify.error(this.$t('startFinishConfigRuntimeFormComponent.configWasNotSaved'));
          this.error_clear();
          this.error_validate(error);
        });
    }
  }
}
</script>

<style>
.is-invalid, .is-invalid * {
  color: red;
}
</style>
